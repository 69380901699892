import dynamic from 'next/dynamic';

export const ExpandableTextButton = dynamic(
    () => import('./ExpandableTextButton').then((m) => m.ExpandableTextButton),
    { ssr: true }
);
export const ExpandableTextButtonV2 = dynamic(
    () =>
        import('./ExpandableTextButtonV2').then((m) => m.ExpandableTextButton),
    { ssr: true }
);
