import React, { type FC } from 'react';
import { useAccount, useIsLoggedIn } from 'bb/account';
import { useRouter } from 'bb/app/router';
import { Box, Flex } from 'bb/ui';
import { CampaignButton } from './CampaignButton';
import { RedeemButton } from './RedeemButton';
import { type SignupCtaProps } from './types';

export type RedeemOrCampaignButtonProps = {
    /**
     * Used for logged in users
     */
    redeemCode?: string;
    /**
     * Used for non-logged in users
     */
    campaignCode?: string;
    /**
     * If true it adds the campaign code to the query.
     *
     * @defaultValue `false`
     */
    addCampaignCodeToQuery?: boolean;
};

export const RedeemOrCampaignButton: FC<
    SignupCtaProps & RedeemOrCampaignButtonProps
> = ({
    name,
    marginTop,
    redeemCode,
    campaignCode,
    variant = 'primary',
    addCampaignCodeToQuery = false
}) => {
    const isLoggedIn = useIsLoggedIn();
    const { account } = useAccount();
    const { routes } = useRouter();

    const code = campaignCode ?? redeemCode;

    return (
        <Box marginTop={marginTop}>
            <Flex justifyContent="center">
                {isLoggedIn && account ? (
                    <RedeemButton
                        variant={variant}
                        name={name}
                        campaignQuery={
                            redeemCode
                                ? {
                                      campaign: redeemCode
                                  }
                                : undefined
                        }
                        route={
                            account.isActivated
                                ? undefined
                                : routes.selectSubscription
                        }
                    />
                ) : (
                    <CampaignButton
                        variant={variant}
                        name={name}
                        campaignQuery={
                            code && addCampaignCodeToQuery
                                ? { campaign: code }
                                : undefined
                        }
                    />
                )}
            </Flex>
        </Box>
    );
};
